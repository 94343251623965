import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown/with-html'

import SEO from '@/components/SEO/Seo'

import Layout from '@/components/Layout'
import LeasingForm from '@/components/Forms/Leasing'
import Map from '@/components/Map'

export const LeasingPageTemplate = ({ data }) => {
  return (
    <>
      <div className='full-height-section'>
        <div className='container py-16 pb-8'>
          <div className='mb-16'>
            <div className='decorator'></div>
            <h1 className='text-center'>{data.title}</h1>
            <ReactMarkdown
              children={data.content}
              allowDangerousHtml
              className='text-grey-dark text-center'
            />
          </div>

          <div className='flex items-center flex-wrap py-8 justify-center'>
            <div className='leasing__form'>
              <div className='contact-form__wrapper'>
                <LeasingForm formTitle='Leasing Enquiries' />
              </div>
            </div>
          </div>

          <div className='contact-blocks'>
            {data.blocks.map((item, i) => {
              return (
                <div className='contact-block' key={i}>
                  <ReactMarkdown
                    children={item}
                    allowDangerousHtml
                    className='contact-block__content'
                  />
                </div>
              )
            })}
          </div>

          <div className='contact-map'>
            <Map
              googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyALWhNZ4Pjlhqb2B831kc7m-g9D5kdaLRM&libraries=geometry,drawing,places`}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div className='contact-map__wrapper' />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </div>
        </div>
      </div>
    </>
  )
}

const LeasingPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <SEO data={frontmatter.seo} />
      <LeasingPageTemplate data={frontmatter} />
    </Layout>
  )
}

LeasingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export const pageQuery = graphql`
  query LeasingPageTemplate {
    markdownRemark(frontmatter: { template: { eq: "leasing" } }) {
      frontmatter {
        title
        content
        blocks
        ...SEO
      }
    }
  }
`

export default LeasingPage
