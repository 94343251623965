import React, { Component } from 'react'

import InputField from './partials/InputField'
import TextField from './partials/TextField'

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      vision: '',
      experience: '',
      offer: '',
      space: '',
      message: '',
      emailValid: true,
      nameValid: true,
      messageValid: true,
      spaceValid: true,
      offerValid: true,
      visionValid: true,
      experienceValid: true,
      formValid: false,
      isSending: false,
      isSend: false,
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
    this.clearForm = this.clearForm.bind(this)
    this.validateForm = this.validateForm.bind(this)
    this.validateField = this.validateField.bind(this)
  }

  handleChange(e) {
    let change = {}
    let name = e.target.name
    let value = e.target.value
    change[name] = value
    this.setState(
      {
        [name]: value,
      },
      () => this.validateField(name, value),
    )
  }

  clearForm() {
    this.setState({
      name: '',
      email: '',
      vision: '',
      experience: '',
      offer: '',
      space: '',
      message: '',
    })
  }

  validateField(fieldName, value) {
    let emailValid = this.state.emailValid
    let nameValid = this.state.nameValid
    let messageValid = this.state.messageValid
    let visionValid = this.state.visionValid
    let experienceValid = this.state.experienceValid
    let offerValid = this.state.offerValid
    let spaceValid = this.state.spaceValid

    switch (fieldName) {
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        break
      case 'name':
        nameValid = value.length >= 1
        break
      case 'message':
        messageValid = value.length >= 1
        break
      case 'vision':
        visionValid = value.length >= 1
        break
      case 'offer':
        offerValid = value.length >= 1
        break
      case 'space':
        spaceValid = value.length >= 1
        break
      case 'experience':
        experienceValid = value.length >= 1
        break
      default:
        break
    }

    this.setState(
      {
        emailValid: emailValid,
        nameValid: nameValid,
        messageValid: messageValid,
        visionValid: visionValid,
        experienceValid: experienceValid,
        spaceValid: spaceValid,
        offerValid: offerValid,
      },
      this.validateForm,
    )
  }

  validateForm() {
    this.setState({
      formValid:
        this.state.emailValid &&
        this.state.nameValid &&
        this.state.visionValid &&
        this.state.experienceValid &&
        this.state.spaceValid &&
        this.state.offerValid,
    })
  }

  handleFormSubmit(e) {
    let contactTitle = this.props.formTitle
    e.preventDefault()
    if (!this.state.isSending && this.state.formValid) {
      this.setState({
        formValid: this.state.emailValid && this.state.nameValid,
      })
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 'form-name': contactTitle, ...this.state }),
      })
        .then(() => {
          this.setState({
            isSending: false,
            isSend: true,
          })
          this.clearForm()
        })
        .catch((error) => alert(error))
    }
  }

  render() {
    const contactTitle = this.props.formTitle
    const statusClass =
      this.state.isSending || this.state.isSend
        ? 'form-container__status form-container__status--active'
        : 'form-container__status'
    const sendingStatus = !this.state.isSend ? (
      <div className='loading-dots loading-dots--blue'>
        <div className='loading-dots--dot'></div>
        <div className='loading-dots--dot'></div>
        <div className='loading-dots--dot'></div>
      </div>
    ) : (
      <h1 className='title-3'>Thank you for reaching out!</h1>
    )

    return (
      <form
        onSubmit={this.handleFormSubmit}
        className='form-container'
        name={contactTitle}
        data-netlify='true'>
        <div className={statusClass}>{sendingStatus}</div>
        <div className='field-grouped'>
          <InputField
            type='text'
            name='name'
            placeholder=''
            label='Name and contact details'
            required={true}
            onChange={this.handleChange}
            error={this.state.nameValid}
            errorMessage="This field can't be empty"
          />
        </div>

        <div className='field-grouped'>
          <InputField
            type='email'
            name='email'
            placeholder=''
            label='Email address'
            required={true}
            onChange={this.handleChange}
            error={this.state.emailValid}
            errorMessage='Provided email is incorrect'
          />
        </div>

        <div className='field-grouped'>
          <TextField
            name='vision'
            label='Detail your vision'
            onChange={this.handleChange}
            required={true}
            small={true}
            error={this.state.visionValid}
            errorMessage="This field can't be empty"
          />
        </div>

        <div className='field-grouped'>
          <TextField
            name='experience'
            label='Detail your business experience'
            onChange={this.handleChange}
            required={true}
            small={true}
            error={this.state.experienceValid}
            errorMessage="This field can't be empty"
          />
        </div>

        <div className='field-grouped'>
          <TextField
            small={true}
            name='offer'
            placeholder=''
            label='Describe the product, produce, food offering that you would like to offer'
            required={true}
            onChange={this.handleChange}
            error={this.state.offerValid}
            errorMessage="This field can't be empty"
          />
        </div>

        <div className='field-grouped'>
          <TextField
            small={true}
            name='space'
            placeholder=''
            label='What are your ideal space requirements '
            required={true}
            onChange={this.handleChange}
            error={this.state.spaceValid}
            errorMessage="This field can't be empty"
          />
        </div>

        <InputField
          type='text'
          name='message'
          label='What equipment, machinery do you need to use'
          onChange={this.handleChange}
          required={false}
          error={this.state.messageValid}
          errorMessage="This field can't be empty"
        />

        <div className='control'>
          <button
            className='btn btn--primary w-full'
            type='submit'
            disabled={this.state.isSending}>
            Submit
          </button>
        </div>
      </form>
    )
  }
}
